import React from "react";
import "./style.scss";
import ReactPlayer from "react-player";
import CustomWelfareSwiper from "./components/swiper";
// import CustomNewSwiperVertical from "./components/news";
import isMobile from "ismobilejs";

const PublicWelfareColumn = (
  props = {
    title: "",
    info: "",
    video: "",
    swiper: [],
    news: [],
    bg: "",
    type: 1,
    bgColor: "",
    subtitleUrl: "",
    subtitle: "",
  }
) => {
  // console.log(props.info);
  // // debugger;
  return isMobile(window.navigator).phone ? (
    <div className={"PublicWelfareColumnMobile"}>
      {props.type === 2 ? (
        <div className={"video"}>
          <div className={"videoContent"}>
            <ReactPlayer
              url={props.video}
              width={"100%"}
              height={"100%"}
              controls={true}
            />
          </div>
        </div>
      ) : (
        <div className={"swiper"}>
          {props.swiper && props.swiper.length ? (
            <CustomWelfareSwiper data={props.swiper} />
          ) : (
            ""
          )}
        </div>
      )}

      <div className={"infoBox"}>
        {props.subtitle ? (
          <a href={props.subtitleUrl} className={"title"}>
            <span>{props.title ?? ""}</span>
            <small>{props.subtitle}</small>
          </a>
        ) : (
          <div className={"title"}>{props.title ?? ""}</div>
        )}
        <div className={"info"}>{props.info ?? ""}</div>
        {/* <div className={"news"}>
          {props.news && <CustomNewSwiperVertical data={props.news} />}
        </div> */}
      </div>
    </div>
  ) : (
    <div
      className={"PublicWelfareColumn"}
      style={{ backgroundColor: props.bgColor ?? "#fff" }}
    >
      {props.bg && (
        <div className={"bg"}>
          <img src={props.bg} alt="" />
        </div>
        )}
        <div className='welfare_adapted_wrap'>
          <div className='welfare_adapted_box'>
            {props.type === 2 ? (
              <div className={"video"}>
                <ReactPlayer
                  url={props.video}
                  width={"100%"}
                  height={"100%"}
                  controls={true}
                />
              </div>
            ) : (
              <div className={"swiper"}>
                {props.swiper && props.swiper.length ? (
                  <CustomWelfareSwiper data={props.swiper} />
                ) : (
                  ""
                )}
              </div>
            )}
            <div className={"infoBox"}>
              {props.subtitle ? (
                <a
                  href={props.subtitleUrl}
                  target="view_window"
                  className={"title"}
                  >
                  <span>{props.title ?? ""}</span>
                  <small>{props.subtitle}</small>
                </a>
              ) : (
                <div className={"title"}>{props.title ?? ""}</div>
              )}
              <div className={"info"}>{props.info ?? ""}</div>
              {/* <div className={"news"}>
                {props.news && <CustomNewSwiperVertical data={props.news} />}
              </div> */}
            </div>
          </div>
        </div>
    </div>
  );
};
export default PublicWelfareColumn;
