import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./index.scss";
import "./indexMobile.scss";
import { map } from "lodash";
import { LifeData } from "../../../../../../utils/solutionData";
import _ from "lodash";
import isMobile from "ismobilejs";

const LifeServices = forwardRef((props, ref) => {
  const { slideId } = props;
  const [current, setCurrent] = useState(LifeData[0]);

  // 暴露给父组件调用的方法
  useImperativeHandle(ref, () => ({
    updateCurrent(key) {
      const item = _.first(_.filter(LifeData, (item) => item.key == key));
      if (item) {
        setCurrent(item);
      }
    },
  }));

  const renderContent = () => {
    if (isMobile(window.navigator).phone) {
      return (
        <div className="life_content">
          <div className="life_content_img_bg">
            <img className="life_content_img" src={current?.icon} />
          </div>
          <div className="life_content_title">{current?.content}</div>
          <div className="life_content_desc">{current?.desc}</div>
        </div>
      );
    }
    return (
      <div className="life_content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: 70,
          }}
        >
          <div className="life_content_title">{current?.content}</div>
          <div className="life_content_desc">{current?.desc}</div>
        </div>
        <div className="life_content_img_bg">
          <img className="life_content_img" src={current?.icon} />
        </div>
      </div>
    );
  };

  const scrollToCenter = (event) => {
    // 获取点击的元素
    const tab = event.currentTarget;
    // 计算需要滑动的距离，使点击的元素居中
    const scrollDistance =
      tab.offsetLeft + tab.clientWidth / 2 - window.innerWidth / 2;
    // 滑动到指定位置
    tab.parentNode.scrollTo({
      left: scrollDistance,
      behavior: "smooth", // 平滑滑动效果
    });
  };

  return (
    <div style={{width: '100%',backgroundColor: '#f8f8f8'}}>
      <div className='life_container_adapted_wrap'>
        <div className='life_container_adapted_box'>
          <div
            className={
              isMobile(window.navigator).phone
                ? "life_container_mobile"
                : "life_container"
            }
            id={slideId}
          >
            <div className="life_title">生活服务</div>
            <div className="life_title_desc">
              学生生活场景，全开发、全连通、全互动
            </div>
            <div className="life_content_bg">
              <div className="life_slider_bg">
                {map(LifeData, (item, i) => {
                  const checked = item.title == current?.title;
                  return (
                    <label
                      key={i}
                      onClick={(event) => {
                        setCurrent(item);
                        scrollToCenter(event);
                      }}
                    >
                      <input
                        type="radio"
                        id={`radio-life-${i}`}
                        name="value-life-radio"
                        value={`radio-life-${i}`}
                        checked={checked}
                      />
                      <span>{item.title}</span>
                    </label>
                  );
                })}
                <span class="selection"></span>
              </div>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
});
export default LifeServices;
