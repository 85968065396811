import enterprise_01 from "../../../../assets/enterprise/coooperate_logo1@2x.png";
import enterprise_02 from "../../../../assets/enterprise/coooperate_logo2@2x.png";
import enterprise_03 from "../../../../assets/enterprise/coooperate_logo3@2x.png";
import enterprise_04 from "../../../../assets/enterprise/coooperate_logo4@2x.png";
import enterprise_05 from "../../../../assets/enterprise/coooperate_logo5@2x.png";
import enterprise_06 from "../../../../assets/enterprise/coooperate_logo6@2x.png";
import enterprise_07 from "../../../../assets/enterprise/coooperate_logo7@2x.png";
import enterprise_08 from "../../../../assets/enterprise/coooperate_logo8@2x.png";
import enterprise_09 from "../../../../assets/enterprise/coooperate_logo9@2x.png";
import enterprise_10 from "../../../../assets/enterprise/coooperate_logo10@2x.png";
import enterprise_11 from "../../../../assets/enterprise/coooperate_logo11@2x.png";
import enterprise_12 from "../../../../assets/enterprise/coooperate_logo12@2x.png";
import enterprise_13 from "../../../../assets/enterprise/coooperate_logo13@2x.png";
import enterprise_14 from "../../../../assets/enterprise/coooperate_logo14@2x.png";
import enterprise_15 from "../../../../assets/enterprise/coooperate_logo15@2x.png";
import enterprise_16 from "../../../../assets/enterprise/coooperate_logo16@2x.png";
import enterprise_17 from "../../../../assets/enterprise/coooperate_logo17@2x.png";
import enterprise_18 from "../../../../assets/enterprise/coooperate_logo18@2x.png";
import enterprise_19 from "../../../../assets/enterprise/coooperate_logo19@2x.png";
import enterprise_20 from "../../../../assets/enterprise/coooperate_logo20@2x.jpg";
import enterprise_21 from "../../../../assets/enterprise/coooperate_logo21@2x.jpg";
import enterprise_22 from "../../../../assets/enterprise/coooperate_logo22@2x.jpg";
import enterprise_23 from "../../../../assets/enterprise/coooperate_logo23@2x.jpg";
import enterprise_24 from "../../../../assets/enterprise/coooperate_logo24@2x.jpg";

const enterprises = [
  { key: "1", logo: enterprise_01 },
  { key: "2", logo: enterprise_02 },
  { key: "3", logo: enterprise_03 },
  { key: "4", logo: enterprise_04 },
  { key: "5", logo: enterprise_05 },
  { key: "6", logo: enterprise_06 },
  { key: "7", logo: enterprise_07 },
  { key: "8", logo: enterprise_08 },
  { key: "9", logo: enterprise_09 },
  { key: "10", logo: enterprise_10 },
  { key: "11", logo: enterprise_11 },
  { key: "12", logo: enterprise_12 },
  { key: "13", logo: enterprise_13 },
  { key: "14", logo: enterprise_14 },
  { key: "15", logo: enterprise_15 },
  { key: "16", logo: enterprise_16 },
  { key: "17", logo: enterprise_17 },
  { key: "18", logo: enterprise_18 },
  { key: "19", logo: enterprise_19 },
  { key: "20", logo: enterprise_20 },
  { key: "21", logo: enterprise_21 },
  { key: "22", logo: enterprise_22 },
  { key: "23", logo: enterprise_23 },
  { key: "24", logo: enterprise_24 },
];

const LoveEnterprise = () => {
  return (
    <div className="tab-partner-content">
      {enterprises.map((ele) => {
        return (
          <div className="tab-item" key={ele.key}>
            <img src={ele.logo} alt="" style={{ objectFit: "contain" }} />
          </div>
        );
      })}
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};

export default LoveEnterprise;
