import partner_01 from "../../../../assets/partner/partner_logo1@2x.png";
import partner_02 from "../../../../assets/partner/partner_logo2@2x.png";
import partner_03 from "../../../../assets/partner/partner_logo3@2x.png";
import partner_04 from "../../../../assets/partner/partner_logo4@2x.png";
import partner_05 from "../../../../assets/partner/partner_logo5@2x.png";
import partner_06 from "../../../../assets/partner/partner_logo6@2x.png";
import partner_07 from "../../../../assets/partner/partner_logo7@2x.png";
import partner_08 from "../../../../assets/partner/partner_logo8@2x.png";
import partner_09 from "../../../../assets/partner/partner_logo9@2x.png";
import partner_10 from "../../../../assets/partner/partner_logo10@2x.png";
import partner_11 from "../../../../assets/partner/partner_logo11@2x.png";
import partner_12 from "../../../../assets/partner/partner_logo12@2x.png";
import partner_13 from "../../../../assets/partner/partner_logo13@2x.png";
import partner_14 from "../../../../assets/partner/partner_logo14@2x.jpg";
import partner_15 from "../../../../assets/partner/partner_logo15@2x.jpg";

const partners = [
  { key: "01", logo: partner_01 },
  { key: "02", logo: partner_02 },
  { key: "03", logo: partner_03 },
  { key: "04", logo: partner_04 },
  { key: "05", logo: partner_05 },
  { key: "06", logo: partner_06 },
  { key: "07", logo: partner_07 },
  { key: "08", logo: partner_08 },
  { key: "09", logo: partner_09 },
  { key: "10", logo: partner_10 },
  { key: "11", logo: partner_11 },
  { key: "12", logo: partner_12 },
  { key: "13", logo: partner_13 },
  { key: "14", logo: partner_14 },
  { key: "15", logo: partner_15 },
];
const CommonwealPartner = () => {
  return (
    <div className="tab-partner-content">
      {partners.map((ele) => {
        return (
          <div className="tab-item" key={ele.key}>
            <img src={ele.logo} alt="" style={{ objectFit: "contain" }} />
          </div>
        );
      })}
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};

export default CommonwealPartner;
