import { useState } from "react";
import "./style.scss";

const steps = {
  2021: [
    "1月，一本书公益计划正式联合发起。",
    "3月4日，北京家音顺达数据技术有限公司捐赠100万元款物，在河南省慈善联合总会设立一本书公益基金。",
    "4月3日，一本书公益开展首次捐赠，向漯河市临颍县窝城镇中心小学、陈庄乡中心小学捐建一本书智能图书管理系统。",
    "4月23日，一本书·益起读公益活动在河南广播大厦正式启动。",
    "5月，启动一期捐赠，重点支援河南省希望小学。海尔集团认捐100万元，用于为河南100个乡村学校各捐建1间一本书智能图书室。",
    "5月14日，第一间一本书公益智慧图书室在登封市卢店镇卢南小学正式落成。",
    "5月18日，向新密市白寨镇史沟希望小学捐建图书室。",
    "5月28日，向周口市扶沟县固城乡初级中学捐建图书室。",
    "6月1日，向南阳市西峡县桑坪镇正弘希望小学、南阳市西峡县海尔希望小学捐建图书室。",
    "6月2日，向中国工农红军河南社旗革命英烈红军小学捐建图书室。",
    "6月10日，携手团省委为中牟县姚家镇土山店希望小学捐建一本书公益图书室。",
    "6月17日，向扶沟县固城乡初级中学捐建图书室。",
    "6月22日，向洛阳新安县正村镇正村交通希望小学、石井镇交通希望小学捐建图书室。",
    "9月，启动二期捐赠，重点支援河南720受灾学校。",
    "9月23日，支援河南省焦作市武陟县嘉应观乡二铺营村党建综合体建设，完捐建首个“一本书·云端书馆”。",
    "10月20日，首个大学生志愿者基地在河南经贸职业学院挂牌成立。",
    "10月22日，向新乡市辉县市占城镇师庄小学捐建图书室。",
    "10月25日，向新乡市卫辉市上乐村镇宋村完全小学捐建图书室。",
    "10月26日，向巩义市西村镇圣水村第八小学捐建图书室。",
    "10月28日，向新乡市辉县市占城镇大占城小学、占城镇北马营小学捐建图书室。",
    "10月29日，向新乡市梨河镇梨河完全小学捐建图书室。",
    "11月26日，向新乡市卫辉市唐岗完全小学捐建图书室。",
    "11月30日，向新乡市辉县市占城镇马张莫小学、陶村小学捐建图书室。",
    "12月3日，向新乡市辉县市占城镇王庄小学、南小营小学捐建图书室。",
    "12月7日，向新乡市辉县市占城镇三位营小学、王官营小学捐建图书室。",
    "12月8日，向新乡市辉县市占城镇西樊村小学捐建图书室。",
    "12月10日，支援南阳市淅川县九重镇村武店村党群服务中心，捐建一本书·云端书馆。",
  ],
  2022: [
    "2月，百家姓爱心粥负责人、著名演员张清老师认捐108万元，用于向河南省内偏远山区的100所乡村学校各捐建1间一本书智能图书室。",
    "3月，携手河南省委组织部、驻村第一书记启动三期捐赠。",
    "3月9日，向荥阳市刘河镇中心小学、白鹤镇中心小学捐建图书室。",
    "3月10日，向洛阳市栾川县冷水河镇中心小学捐建图书室。",
    "6月15日，向南阳市方城县杨楼镇邵岗小学捐建图书室。",
    "6月16日，向南阳市南召县城郊乡竹园沟小学、南阳市淅川县九重镇邹庄村部捐建图书室。",
    "6月17日，向南阳市淅川县丹阳镇下孔村、桐柏县新集乡磨沟村部捐建图书室。",
    "6月30日，向周口市扶沟县大李庄乡冯老小学、西华县艾岗乡半截楼村小学捐建图书室。",
    "7月1日，向周口市西华县红花镇龙池头小学捐建图书室。",
    "7月11日，向南阳市南召县太山庙中心小学捐建图书室。",
    "7月12日，向邓州市龙堰乡刁河回族小学捐建图书室。",
    "7月13日，向南阳市内乡县瓦亭镇中心小学、曹营小学、启帆小学、杨沟小学、曹营村村部捐建图书室。",
    "9月，启动四期捐赠。",
    "9月2日，向驻马店市上蔡县高李村高李小学捐建图书室。",
    "9月26日，向驻马店市上蔡县孟楼小学捐建图书室。",
    "9月27日，向驻马店市汝南县臻头河小学捐建图书室。",
    "11月，河南省教育厅·一本书志愿服务队成立。",
  ],
  2023: [
    "1月，荣获“河南省优秀志愿服务项目”。",
    "2月20日，向濮阳市濮阳县郎中乡马白邱小学捐建图书室。",
    "2月21日，向漯河市舞阳县侯集镇余庄小学捐建图书室。",
    "2月23日，第二个大学生志愿者基地在河南农业大学信息与管理科学学院成立。",
    "2月23日，向灵宝市寺河乡实验学校捐建图书室。",
    "3月3日，向新乡市封丘县应举镇东大村小学捐建图书室。",
    "3月9日，向平顶山市鲁山县赵村镇第一小学捐建图书室。",
    "3月16日，向登封市东华镇第二小学捐建图书室。",
    "4月23日，在甘肃省兰州市发布全国公益捐助计划，成立中外阅读学研究专业委员会一本书公益全国推广中心和一本书公益阅读指导专家团。",
    "5月24日，向永城市龙岗镇孟李楼小学、芒山镇僖山小学捐建图书室。",
    "6月19日，向新疆喀什巴楚县阿纳库勒乡第四小学、第七小学捐建图书室。",
    "8月，荣获“河南省2023年文明实践志愿服务优秀项目”。",
    "9月26-27日，向甘肃省临夏回族自治州东乡族自治县柳树乡马百户小学、陇南礼县雷坝镇中心小学捐建图书室。",
    "10月17日，向郑州航空港区沙张小学捐建图书室。",
    "10月24日，向甘肃省康乐县周家沟小学捐建图书室。",
    "11月22日，向开封市龙亭区水稻乡马头小学捐建图书室。",
    "12月4日，首个一本书图书漂流屋落地郑州市紫荆山地铁站。",
    "12月27日，向信阳市潢川县魏岗乡中心小学捐建图书室。"
  ],
  2024: [
    "1月18日，开展2023年度“阅读之星”、“阅读星学校”表彰。",
    "3月5日，参加2024年河南省直学雷锋志愿服务日志愿服务项目展示交流活动。",
    "4月19~22日，“世界读书日”系列公益活动登陆央视1套、13套及河南卫视。",
    "4月23日，第三个大学生志愿者基地在郑州升达经贸管理学院挂牌成立。",
    "4月30日，北京实验学校（海淀）小学为新疆乌鲁木齐乡村同学捐赠爱心图书。",
    "5月7日，向叶县廉村镇实验学校捐建图书室。",
    "5月8日，向信阳市新县田铺乡九年一贯制学校捐建图书室。",
    "5月22日，向淮滨县邓湾乡罗营村罗营小学捐建图书室。",
    "6月18日，携手中建二局装饰有限公司北方分公司发起“爱与饰界同行暨一本书公益”图书捐赠活动。",
    "7月6日，出席2024中外阅读大会暨第三届学术研讨会并发布“一本书·我的读书故事”系列公益视频计划。",
    "8月1日，携手中建二局装饰工程有限公司北方分公司党总支共同举办“爱与饰界同行暨一本书公益‘我的读书故事’”主题党日活动。",
    "9月6日，携手中建二局装饰工程有限公司北方分公司党总支向鹤壁市浚县王庄镇井固村中心校捐建图书室。",
    "9月29日，携手郑州四中实验学校教育集团共同开展图书募捐活动。",
    "10月30日，携手中原银行总行多个部门向叶县洪庄杨镇中心小学捐建图书室。",
    "12月6日，携手郑州市黄河水利委员会小学举办“益起读好书·快乐共成长”的图书捐赠活动。",
    "12月12日，在河南省教育厅关工委的指导下，携手举行罗山县铁铺镇中心学校“豫见未来，书送梦想”图书室捐赠仪式。",
  ]
};
const CustomStep = () => {
  const [curStep, setCurStep] = useState("2024");
  const handleChange = (year) => {
    setCurStep(year);
    setLoadMore(false);
  };
  const [loadMore, setLoadMore] = useState(false);
  const handleClick = () => {
    setLoadMore(true);
  };

  return (
    <div className="content-wrap">
      <div className="left-box">
        <div className="column-line">
          <div
            className={`row-box position1 ${
              curStep === "2024" ? "active" : ""
            }`}
            onClick={() => handleChange("2024")}
          >
            <div className="year">2024</div>
          </div>
          <div
            className={`row-box position2 ${
              curStep === "2023" ? "active" : ""
            }`}
            onClick={() => handleChange("2023")}
          >
            <div className="year">2023</div>
          </div>
          <div
            className={`row-box position3 ${
              curStep === "2022" ? "active" : ""
            }`}
            onClick={() => handleChange("2022")}
          >
            <div className="year">2022</div>
          </div>
          <div
            className={`row-box position4 ${
              curStep === "2021" ? "active" : ""
            }`}
            onClick={() => handleChange("2021")}
          >
            <div className="year">2021</div>
          </div>
        </div>
      </div>
      <div className="right-box">
        <div className="right-content">
          <div className="content-title">{curStep}年</div>
          <div className={`content-list ${loadMore ? "expand" : ""}`}>
            {steps[curStep].map((ele) => (
              <div key={ele} className="list-row">
                {ele}
              </div>
            ))}
          </div>
          {!loadMore && (
            <div className="load-more" onClick={handleClick}>
              点击查看更多
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomStep;
